<template>
    <div class="page">
        <top-bar :title="'上传救助结果'"></top-bar>
<!--        <div class="line"></div>-->
        <div class="content">
            <div class="tit">求助详情</div>
            <van-cell-group>
                <van-cell title="求助类型" :value="appJqSalvation.helpTypeName" />
                <van-cell title="求助人" :value="appJqSalvation.name" />
                <van-cell title="求助人身份证" :value="appJqSalvation.idNumber" />
                <van-cell title="求助人电话" :value="appJqSalvation.mobile" />
                <van-cell title="求助地址" :value="appJqSalvation.specificPlace" />
                <van-cell title="救助描述" :label="appJqSalvation.salvationDesc" />
            </van-cell-group>
            <div class="img">
                <van-uploader v-model="fileList" :deletable="false" :show-upload="false" readonly />
            </div>
            <div class="line"></div>
            <div class="tit">救助详情</div>
            <div class="result">
                <van-form @submit="onSubmit" input-align="right" error-message-align="right">
                    <van-field required v-model="formData.knotUser" name="knotUser" label="核对人" placeholder="请输入" :rules="[{ required: true, message: '请填写核对人' }]" />
                    <van-field required readonly clickable name="salvationStatus" :value="formData.salvationStatus" label="救助状态" placeholder="请选择" @click="showPickerFun('salvationStatus')" :rules="[{ required: true, message: '请选择救助状态',triggle:'Onchange' }]" />
                    <van-field name="radio" label="在驾驶舱显示">
                        <template #input>
                            <van-radio-group v-model="formData.driveStatus" direction="horizontal">
                                <van-radio :name="0">显示</van-radio>
                                <van-radio :name="1">不显示</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field required v-model="formData.partnersDesc" name="partnersDesc" rows="2" autosize label="求助描述" type="textarea" placeholder="请输入" :rules="[{ required: true, message: '请填写求助描述' }]" />
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="statusOption" @confirm="popupConfirm" @cancel="showPicker = false" v-if="type == 'salvationStatus'" />
                    </van-popup>
                    <div class="btn" v-if="!showPicker">
                        <van-button round block type="info" native-type="submit">确定上传</van-button>
                    </div>
                </van-form>
                <div class="img">
                    <van-uploader v-model="fileList2" @delete="uploaddel" :after-read="uploadRead" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { uploadImg } from '@/utils/common'
import { mapState } from 'vuex'
import { Toast } from "vant";
import { setTimeout } from 'timers';
export default {
    data() {
        return {
            dir: 'files/future/negotiate/temporary',
            type: '',
            showPicker: false,
            fileList: [],
            fileList2: [],
            statusOption: [
                { text: "待审核", value: 0 },
                { text: "审核通过", value: 1 },
                { text: "救助中", value: 2 },
                { text: "救助完成,待评价", value: 3 },
                { text: "已评价", value: 4 },
                { text: "审核不通过", value: 6 },
            ],
            appJqSalvation: {},
            formData: {
                knotUser: '',
                driveStatus: 1,
                salvationDesc: '',
                salvationStatus: '',
            },
            newFileMessages: []
        }
    },
    created() {
        this.id = this.$route.query.id;
        this.getDataList();
    },
    beforeDestroy() {

    },
    methods: {
        getDataList() {
            this.$http({
                url: this.$http.adornUrl(
                    `/wxapp/app/appjqsalvation/infoCheck/${this.id}`
                ),
                method: "get",
                params: this.$http.adornParams(),
            }).then(({ data }) => {
                if (data.code == 0) {
                    console.log(data);
                    this.appJqSalvation = data.appJqSalvation;
                    let f = this.statusOption.filter(item => {
                        return item.value == data.appJqSalvation.salvationStatus
                    })
                    this.formData.salvationStatus = f[0].text
                    if (data.appJqSalvation.fileMessages) {
                        data.appJqSalvation.fileMessages.map((item) => {
                            this.fileList.push({ url: item.objectUrl });
                        });
                    }
                }
            });
        },
        // 删除图片
        uploaddel(file, detail) {
            let i = detail.index
            this.newFileMessages.splice(i, 1)
        },
        // 上传图片
        uploadRead(file) {
            this.$toast.loading({
                message: '上传中...',
                forbidClick: true,
                duration: 0,
                overlay: true
            });
            uploadImg(file.file, this.dir, (data) => {
                this.$toast.clear()
                // console.log("data",data)
                if (data.fileMessage) {
                    this.newFileMessages.push(data.fileMessage)
                } else {
                    this.$toast.console.error({ message: '上传失败' })
                }
            })
        },
        showPickerFun(type) {
            console.log(type)
            this.type = type;
            this.showPicker = true;
        },
        popupConfirm(e) {
            console.log("e", e)
            this.formData.salvationStatus = e.text;
            this.showPicker = false;
        },
        onSubmit(val) {
            console.log("val", val)
            let f = this.statusOption.filter(item => {
                return item.text == val.salvationStatus
            })
            val.salvationStatus = f[0].value;
            console.log("vba", val)
            this.$http({
                url: this.$http.adornUrl(`/wxapp/app/appjqsalvation/saveResult`),
                method: "post",
                data: this.$http.adornData({
                    id: this.id,
                    ...val,
                    newFileMessages: this.newFileMessages
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    Toast.success('操作成功')
                    setTimeout(() => {
                        this.$router.push({ name: 'rescue-list' })
                    }, 1000)
                } else {
                    Toast.fail(res.msg)
                }
            });
        }
    },

    components: {
        topBar
    },
}
</script>
<style  lang="scss" scoped>
.page {
  padding: 0 0 120px 0;
  min-height: 100%;
  background: #fff;
  box-sizing: border-box;
  .content {
    font-size: 24px;
    .tit {
      padding: 30px 0 30px 40px;
      position: relative;
      color: #000;
      font-weight: bold;
      font-size: 26px;
      //   border-bottom: 1px solid #eee;
      &::before {
        content: "";
        width: 4px;
        height: 26px;
        background: #017afd;
        position: absolute;
        left: 30px;
        top: 30px;
      }
    }
    .img {
      padding: 20px 30px;
    }
  }
}
.line {
  height: 20px;
  width: 100%;
  background: #f5f5f5;
}
.btn {
  width: 90%;
  position: fixed;
  bottom: 0;
  left: 5%;
  height: 100px;
  z-index: 9999;
}
</style>
